import React from "react";
import HousePage from "../../components/HousePage";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";


const HousesPage = ({lang}) => {
  return (
    <>
      <Helmet>
        <meta property="keywords" content={lang === 'EN' ? 'Camp SPA, Модульная сауна с панорамным остеклением, просторной террасой и уличной купелью на дровах.' : 'Camp SPA, Модульная сауна с панорамным остеклением, просторной террасой и уличной купелью на дровах.'} />
        <meta property="og:url" content="https://modular-house.by/what-we-do/modelSPA/" />
      </Helmet>

      <Layout 
        pageTitle={lang === 'EN' 
        ? 'Модульная сауна "Camp SPA" с панорамным остеклением, просторной террасой и уличной купелью на дровах от компании BY home, Беларусь' 
        : 'Модульная сауна "Camp SPA" с панорамным остеклением, просторной террасой и уличной купелью на дровах от компании BY home, Беларусь'}
        pageDescription={lang === "EN"
        ? 'Camp SPA - это идеальный выбор для тех, кто ищет компактную и уютную сауну. Мы использовали только экологически чистые материалы и новейшие технологии, чтобы создать баню, которая будет соответствовать всем требованиям современного человека.'
        : 'Camp SPA - это идеальный выбор для тех, кто ищет компактную и уютную сауну. Мы использовали только экологически чистые материалы и новейшие технологии, чтобы создать баню, которая будет соответствовать всем требованиям современного человека.'}  
        page="house" 
        component = {HousePage} 
        house='spa' />
    </>
  );
};
export default HousesPage;